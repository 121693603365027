html{
  background: url("./assets/fortnite-leaderboard-bg.png") no-repeat center center fixed;
  background-size: cover;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  color: rgba(255, 255, 255, 0.87);
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1300px)  {
  html,
  body{
    display: inherit;
  } 
}


a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;  
  letter-spacing: -0.15px;
  margin: 0;

}

button{
  cursor: pointer;
}

@media only screen and (max-width: 670px)  {
  h3{
    max-width: fit-content;  
  }
}

@media only screen and (max-width: 599px)  {
  h3{
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;  
  }
}

h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;  
  letter-spacing: 0.1px;
  margin: 0;
}

p{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  margin-top: 10px;
}